import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PolicyTemplate from '../../templates/pages/policy';

const Privacy = ( { data } ) => {
	const { file: { childMarkdownRemark: { frontmatter: { copy } } } } = data;

	return (
		<div>
		<Helmet>
				<link rel="stylesheet" href="https://kinetic-styles.netlify.app/Styles.css" />
		</Helmet>
		<PolicyTemplate
			title="privacy"
			copy={ copy }
			heading="Privacy Policy"
		/>
		</div>
	);
};

Privacy.propTypes = { data: PropTypes.object };

export default Privacy;

export const query = graphql`
query Privacy {
  file(relativePath: {eq: "policy/privacy.md"}) {
    childMarkdownRemark {
      frontmatter {
        copy
      }
    }
  }
}
	`;
